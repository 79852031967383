@import 'colors.scss';

.item-list-component {
  /*#region action-buttons-column*/
  // column with action buttons (depends on number of buttons)
  @mixin action-buttons-column($number) {
    min-width: $number * 20px + 20px !important;

    clr-icon {
      cursor: pointer;
    }
  }
  // column with one action button
  .one-button-column {
    @include action-buttons-column(1); // 40 = 1*20 + 20
  }
  // column with two action buttons
  .two-buttons-column {
    @include action-buttons-column(2); // 60 = 2*20 + 20
  }
  // column with four action buttons
  .three-buttons-column {
    @include action-buttons-column(3); // 80 = 3*20 + 20
  }
  // column with four action buttons
  .four-buttons-column {
    @include action-buttons-column(4); // 100 = 4*20 + 20
  }
  // column with five action buttons
  .five-buttons-column {
    @include action-buttons-column(5); // 120 = 5*20 + 20
  }
  .six-buttons-column{
    @include action-buttons-column(6); // 140 = 6*20 + 20
  }
  /*#endregion action-buttons-column*/
  /*#region tab-action-buttons-column*/
  // column with action buttons (depends on parameters)
  @mixin tab-action-buttons-column($width) {
    width: $width + px;
  }
  // if tab is not first and there is one icon
  .tab-one-button-column {
    @include action-buttons-column(1);
    max-width: 40px;
  }
  // if tab is not first and there are two icons
  .tab-two-buttons-column {
    @include tab-action-buttons-column(2);
    min-width: 60px;
  }
  /*#endregion tab-action-buttons-column*/
  .header-button {
    margin: 0;
    margin-top: -12px;
    float: left;
  }

  .header-button-tabbed {
    margin: 0;
    margin-top: 12px;
    float: left;
  }

  .grid-buttons {
    text-align: right;

    clr-icon {
      cursor: pointer;
    }
  }

  .grid-butons-tabbed {
    margin-top: 15px;
    float: right;
  }
  // datagrid is bigger than window size
  .datagrid-overlay-wrapper {
    overflow-x: auto;
  }

  .datagrid {
    margin: 10px 0 0 0;

    .datagrid-column {

      clr-checkbox {
        label {
          line-height: 24px;
        }
      }
    }
  }
  // hide empty area on print and export, pagination - datagrid goes out of the window
  .datagrid-host {
    display: initial;
  }
  /*#region action-buttons-cell*/
  // cell with action buttons (depends on parameters)
  @mixin action-buttons-cell($number) {
    min-width: $number * 8px + 44px !important;
    text-align: right;

    clr-icon {
      cursor: pointer;
    }
  }
  // cell with one action button
  .one-button-cell {
    @include action-buttons-cell(1); // 16 (52) = 8x1 + 44
    max-width: 40px;
  }
  // cell with two action buttons
  .two-buttons-cell {
    @include action-buttons-cell(2); // 60 = 8x2 + 44
  }
  // cell with four action buttons
  .three-buttons-cell {
    @include action-buttons-cell(3); // 68 = 8x3 + 44
  }
  // cell with four action buttons
  .four-buttons-cell {
    @include action-buttons-cell(4); // 76 = 8x4 + 44
  }
  // cell with four action buttons
  .five-buttons-cell {
    @include action-buttons-cell(5); // 84 = 8x5 + 44
  }
  .six-buttons-cell{
    @include action-buttons-cell(6); // 92 = 8x6 + 44
  }
  /*#endregion action-buttons-cell*/
  .datagrid-cell clr-icon[shape="check"] {
    color: $datagrid-checked;
    font-weight: bold;
    height: 20px;
    width: 20px;
  }

  .datagrid-cell clr-icon[shape="times"] {
    color: $datagrid-unchecked;
    font-weight: bold;
    height: 20px;
    width: 20px;
  }

  .datagrid-cell clr-icon[shape="play"] {
    color: $datagrid-checked;
    font-weight: bold;
    height: 20px;
    width: 20px;
  }

  .datagrid-cell clr-icon[shape="stop"] {
    color: $datagrid-unchecked;
    font-weight: bold;
    height: 20px;
    width: 20px;
  }
  // matrix with header buttons
  .matrix-header-button {
    margin-top: -20px;
  }
  // matrix in tab
  .tab-matrix {
    margin-bottom: -15px;
  }
}

@media print {
  body * {
    visibility: hidden;
  }

  .header.header-component {
    position: static;

    .branding .title {
      visibility: visible;
      position: absolute;
      top: 0px;
      left: 50px;
      color: $header-title-color;
      z-index: 9999999;
    }
  }

  #printSection * {
    visibility: visible;
  }

  .dx-datagrid-scroll-container.dx-datagrid-content-fixed {
    position: relative !important;
  }

  #printSection {
    position: absolute;
    margin: auto;
    left: 24px;
    right: 24px;
    top: 48px;

    .no-print *,
    .tooltip.tooltip-top-left > .tooltip-content,
    .tooltip.tooltip-top-left > .tooltip-content::before,
    .tooltip-content, .tooltip-content *,
    .datagrid-foot select {
      display: none;
    }
  }
}

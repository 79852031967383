@import "~ng-pick-datetime/assets/style/picker.min.css";
@import 'colors.scss';
@import "~@ng-select/ng-select/themes/default.theme.css";

/*.btn.btn-primary {
  border-color: $primary-color;
  background-color: $primary-color;
  color: $text-color;

  &:hover {
    background-color: $hover-color;
    color: $text-color;
  }
}

.btn.btn-default {
  border-color: $primary-color;
  background-color: $text-color;
  color: $primary-color;
  outline: none;

  &:hover {
    background-color: $secondary-background-color;
    color: $secondary-color;
  }
}*/

// styling for date time picker
.cdk-overlay-container {
  z-index: 10000;
}

.owl-dt-popup-container {
  width: 290px;
}

.owl-dt-popup-container .owl-dt-calendar {
  height: 13.25em;
}

.owl-dt-calendar-control {
  font-size: 14px;
}

.owl-dt-calendar-table .owl-dt-calendar-header {
  font-size: 18px;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: $primary-color;
}

.owl-dt-timer-content .owl-dt-timer-input {
  font-size: 14px;
}

.owl-dt-timer-content {
  margin: 0em 0.2em 0.6em 0.2em;
}

.owl-dt-popup-container .owl-dt-timer {
  height: 85px;
}

.owl-dt-timer-box {
  .owl-dt-control-arrow-button .owl-dt-control-button-content {
    padding: 4px;
  }

  .owl-dt-control-button {
    margin-top: -18px;
  }

  width: 20%;
}

.owl-dt-timer-divider:before {
  width: 4px;
  height: 4px;
}

.owl-dt-timer-divider:after {
  width: 4px;
  height: 4px;
}

.owl-dt-container-row:last-child {
  color: #000;
}

.owl-dt-container-control-button .owl-dt-control-button-content {
  font-size: 14px;
}

.owl-dt-container-buttons {
  height: 1.6em;
}



/*.btn.btn-secondary, .btn.btn-info, .btn.btn-outline, .btn.btn-primary-outline, .btn.btn-secondary-outline, .btn.btn-outline-primary, .btn.btn-outline-secondary, .btn.btn-info-outline {
  border-color: $primary-color;
  background-color: transparent;
  color: $primary-color;
}

.btn.btn-secondary:active, .btn.btn-info:active, .btn.btn-outline:active, .btn.btn-primary-outline:active, .btn.btn-secondary-outline:active, .btn.btn-outline-primary:active, .btn.btn-outline-secondary:active, .btn.btn-info-outline:active {
  box-shadow: 0 1px 0 0 $primary-color inset;
}

.btn.btn-secondary:hover, .btn.btn-info:hover, .btn.btn-outline:hover, .btn.btn-primary-outline:hover, .btn.btn-secondary-outline:hover, .btn.btn-outline-primary:hover, .btn.btn-outline-secondary:hover, .btn.btn-info-outline:hover {
  background-color: $secondary-background-color;
  color: $secondary-color;
}*/

input[type=time], input[type=date] {
  margin-top: 3px;
  border: none;
  border-bottom: 1px solid $border-botom-time;
  padding-right: 0;
  height: 24px;
}

input[type=time]:focus {
  outline: none;
}

input[type=text]:not([readonly]):focus, input[type=password]:not([readonly]):focus, input[type=number]:not([readonly]):focus, input[type=email]:not([readonly]):focus, input[type=url]:not([readonly]):focus, input[type=tel]:not([readonly]):focus, input[type=date]:not([readonly]):focus, input[type=time]:not([readonly]):focus {
/*  background: -webkit-linear-gradient(top, transparent 95%, $primary-color 95%);
  background: linear-gradient(to bottom, transparent 95%, $primary-color 95%);
  border-bottom: 1px solid $primary-color;*/
  background-size: 100% 100%;
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  margin-bottom: 1px;
}

.nav .nav-item.active > .nav-link {
  box-shadow: 0 -3px 0 $secondary-color inset;
}

.nav .nav-link:hover, .nav .nav-link.active {
  box-shadow: 0 -3px 0 $hover-color inset;
}

.nav .nav-link:focus {
  outline: none;
}

.checkbox input[type="checkbox"]:checked + label::before, .checkbox-inline input[type="checkbox"]:checked + label::before {
  background: $primary-color;
}

clr-checkbox {
  margin: 0;
}

textarea:focus {
  outline: 0;
  box-shadow: 0 0 2px 2px $hover-color;
}

.datagrid-placeholder {
  display: none;
}

.radio input[type="radio"]:checked + label::before, .radio-inline input[type="radio"]:checked + label::before {
  box-shadow: inset 0 0 0 6px $hover-color;
  border: none;
}

form .form-group .select {
  margin-top: 1px;
}

a.tooltip {
  color: $tooltip-icon;

  &:focus > :first-child {
    outline: none;
  }
}

// links get their styling stripped in our default style. Use this when you want visible links.
a.highlight{
    color: $primary-color !important;
    text-decoration: underline !important;
}

.btn.radio {
  border-color: $primary-color;
  background-color: transparent;
  color: $primary-color;

  input[type="radio"]:checked + label {
    background-color: $primary-color;
    color: $text-color;
  }
}

.btn.radio:hover {
  background-color: $hover-color;
  color: $text-color;
}

// hide tab if edit mode
.nav-item.hide {
  display: none;
}

// wider tooltip-content when more text
.tooltip .tooltip-content {
  width: auto !important;
}

.tooltip .tooltip-content.tooltip-xs, .tooltip.tooltip-xs > .tooltip-content {
  max-width: 3rem !important;
}

.tooltip .tooltip-content.tooltip-sm, .tooltip.tooltip-sm > .tooltip-content {
  max-width: 5rem !important;
}

.tooltip .tooltip-content.tooltip-md, .tooltip.tooltip-md > .tooltip-content {
  max-width: 10rem !important;
}

.tooltip .tooltip-content.tooltip-lg, .tooltip.tooltip-lg > .tooltip-content {
  max-width: 15rem !important;
}

.tooltip.tooltip-validation.invalid > .dt-input > input {
  border-bottom: 1px solid #c92100;
  background: -webkit-gradient(linear,left top, left bottom,color-stop(95%, transparent),color-stop(0, #c92100));
  background: linear-gradient(180deg,transparent 95%,#c92100 0);
  -webkit-transition: none;
  transition: none;
}

// smaller dialog
.modal-dialog .inner-dialog .modal-dialog {
  min-width: initial;
}

/*#region card-settings*/

.card {
  margin: 0;

  .card-block {
    padding-top: 0;
    padding-bottom: 0;
  }
}

/*#endregion card-settings*/

/*#region stack-block*/
.stack-block {
  padding: 0;

  .stack-children {
    // custom set for open slack
    .stack-block-label {
      width: 0;
      flex-basis: 0;
      padding: 0;
    }

    .stack-block-content {
      padding: 0;

      .datagrid {
        margin: 0;
      }
    }
  }

  &.stack-block-expanded > .stack-block-label, &.stack-block-expanded > .stack-block-content {
    background-color: $secondary-background-color;
  }
}
/*#endregion stack-block*/

.pagination-fixed {
  /*position: fixed;
    right: 35px;*/
  display: flex;
}

.datagrid-body {
  min-height: 1px;

  .datagrid-row:hover {
    cursor: pointer;
  }
}

/*#region content component style*/
.main-container.content-component {

  .content-container {
    .content-area {
      .contents {
        overflow: auto;
        height: 100%;
        max-height: calc(100% - 60px);
      }
    }
  }
}


.owl-dt-calendar-table .owl-dt-calendar-cell-active:focus > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected), .owl-dt-calendar-table :not(.owl-dt-calendar-cell-disabled):hover > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
  background-color: #71B74B47
}

.alert-component {
  div.alert.alert-app-level {
    margin-left: -24px;
    margin-right: -24px;
    top: -24px;
  }
}

.product-editor-component .datagrid-scroll-wrapper {
  max-height: calc(100vh - 225px);
}

.current-lot-property-component {
  .excluded-suppliers-group {
    ng-select {
      max-width: 300px;
    }

    .ng-dropdown-panel {
      .scroll-host {
        height: 190px;
      }
    }
  }
}

.nav-group {
  .nav-group {
    margin-top: 0;
  }
}

.collapsible {
  .collapsible {
    label {
      padding-left: 0;
    }

    .nav-list {
      padding-left: 5px;
    }
  }
}

.footer-pagination {
  display: inline;
}

.datagrid-foot .pagination {
  margin-top: -33px;
}

.card {
  margin-bottom: 10px;
}

.grid-cell {
  &__number {
    text-align: right !important;
  }
}

.product-editor-component {
  .datagrid {
    max-height: calc(100vh - 260px);
  }
}

.buyer-supply-matrix-lots-component {
  .modal-dialog.modal-xl {
    width: 100%;
  }
}

.lot-multi-edit-component {
  .modal-dialog.modal-xl {
    width: 100%;
  }

  .cb-wrapper {
    .checkbox {
      display: inline-block;
      position: relative;
      top: 8px;
    }
  }
}

.lots-component.vertical {
  .datagrid-overlay-wrapper {
    min-height: 45%;
  }
}

.item-list-component .datagrid {
  max-height: calc(100vh - 240px);
}

.item-list-component.vertical.master-details-showing .datagrid {
  max-height: calc(100vh - 548px);
}

form .form-group .ng-select {
  input[type=text][role="combobox"] {
    margin: 0;
  }
}

form .required:after{
  content: "*";
  font-size: 1.1em;
  color: #c92100;
  margin-left: 0.25rem;
}

.prebid-component .modal-dialog.modal-xl {
  width: 100%;
}

.lots-component {
  .translatable-wrapper {
    .selector-buttons {
      margin-left: 60px;
      position: absolute;
    }
  }
}

.checkbox-column.datagrid-cell {
  text-align: center;
}

.master-details-grid.master-details-showing {
  .datagrid {
    height: 275px;
  }
}

.imported {
  color: #808080;
  font-weight: lighter;
}

.test .content-container {
  border-top: 7px dashed red;
}

.sidenav, .sidenav .nav-group label, .sidenav .sidenav-content > .nav-link {
  font-family: 'Ubuntu', 'Helvetica Neue','Segoe UI',Helvetica,Verdana,sans-serif;
}

.dx-link-icon, .dx-checkbox-icon, .dx-link i {
  color: $primary-color !important;
}

[name="showAuctionUsers"] {
  margin-bottom: 14px !important;
}

[name="showSupplierUsers"] {
  margin-bottom: 14px !important;
}

[name="showPlatformUsers"] {
  margin-bottom: 14px !important;
}

[name="showBuyerUsers"] {
  margin-bottom: 14px !important;
}

.users-component .filter-column {
  margin-top: 0px !important;
}

#user-filter-settings dx-check-box {
  margin-bottom: 14px;
}

//EFICE
.efice .bottom-logo-wrapper {
  display: none !important;
}

.efice .sidenav, 
.efice .sidenav ::-webkit-scrollbar-track, 
.efice .au-sidebar-bottom-brand-area,
.efice .dx-checkbox-checked .dx-checkbox-icon,
.efice .dx-popup-title,
.efice .dx-pager .dx-page-sizes .dx-selection, 
.efice .dx-pager .dx-pages .dx-selection,
.efice .dx-switch-on-value .dx-switch-container::before,
.efice .switch-button.dx-switch-on-value .dx-switch-handle:before, 
.efice .switch-button-decimal.dx-switch-on-value .dx-switch-handle:before
{
  background-color: #2B4591 !important;
}
.efice .dx-checkbox-icon{
  border-color: #2B4591 !important;
}
.efice .dx-datagrid-filter-panel .dx-datagrid-filter-panel-clear-filter, 
.efice .dx-datagrid-filter-panel .dx-datagrid-filter-panel-text,
.efice .dx-checkbox-icon,
.efice .dx-link-icon, 
.efice .dx-checkbox-icon, 
.efice .dx-link i
{
  color: #2B4591 !important;
}

// only needed for on state, off can keep default dark color
.efice .dx-switch-on
{
    color: white !important;
}

.efice button.btn.btn-primary,
.efice .au-button,
.efice .au-button.dx-button,
.efice .dx-button-has-text:not(.dx-datagrid-column-chooser-button):not(.dx-dialog-button):not(.dx-datagrid-addrow-button):not(.dx-datagrid-export-button) {
  background-color: #2B4591;
}

.efice button.btn.btn-primary:hover:not(button.btn:disabled:hover), 
.efice button.btn.btn-outline:hover, 
.efice button.btn.btn-secondary:hover, 
.efice .au-button:hover, 
.efice .dx-button-has-text:not(.dx-datagrid-column-chooser-button):not(.dx-dialog-button):not(.dx-datagrid-addrow-button):not(.dx-datagrid-export-button):hover
{
  background-color: #08257c;
}

.efice .sidenav .sidenav-content .au-sidebar-menu-item > .au-sidebar-menu-item-link.active, 
.efice .au-sidebar-menu-item:hover, 
.efice .au-sidebar-menu-section-item:hover {
  background-color: #6987e0 !important;
}

.efice .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>td, 
.efice .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused)>tr>td, 
.efice .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>td, 
.efice .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover>tr>td{
  background-color: #ccd9ff;
}

.efice .header-component {
  background: #2B4591 !important;
}

// hide supply forecast by default...
.nav-item:has(.public-supply-forecast-urk), .public-supply-forecast-urk {
  display: none;
}

// ..unless efice class is used
.efice .main-container.content-component .nav-item:has(.public-supply-forecast-urk), .efice .main-container.content-component .public-supply-forecast-urk {
  display: block !important;
}

.efice .main-container .content-container {
  height: 100% !important;
}

.efice .sidenav ::-webkit-scrollbar-thumb {
  background: #002185 !important;
}

// DEV EXTREME OVERWRITES
.dx-info, .dx-pages {
  visibility: visible !important;
}

.dx-layout-manager .dx-field-item:has(input[aria-hidden=true]) {
  padding: 0 !important;
}

.dx-datagrid-headers .dx-header-row {
  background-color: rgb(249, 249, 249);
}

.dx-datagrid-table .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > td:not(.dx-focused) {
  background-color: rgba(0,0,0,0.1) !important;
}

.switch-button.dx-switch.dx-state-hover .dx-switch-handle:before,
.switch-button-decimal.dx-switch.dx-state-hover .dx-switch-handle:before {
  background-color: $hover-color !important;
}

.switch-button .dx-switch-handle:before,
.switch-button-decimal .dx-switch-handle:before {
  background-color: $primary-color !important;
}

.switch-button.dx-switch.dx-state-hover.dx-switch-on-value .dx-switch-handle:before,
.switch-button-decimal.dx-switch.dx-state-hover.dx-switch-on-value .dx-switch-handle:before {
  background-color: $primary-color !important;
}

.switch-button.dx-switch-on-value .dx-switch-handle:before,
.switch-button-decimal.dx-switch-on-value .dx-switch-handle:before {
  background-color: $primary-color !important;
}

.clr-checkbox-wrapper input[type=checkbox]:checked + label::before {
  background-color: $primary-color;
}

// override DX style for transaction monitor icons when using custom/inverted background
tr.inverted * {
  color: white !important;
  border-color: white !important;
}
